<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card class="pl-4 pr-4 pt-2 pb-2"  >
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="subscriptionPlanAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row class="mb-3 pt-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="subscriptionPlanObjList && subscriptionPlanObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="subscriptionPlanObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(subs_plan_modules)="data">
                    <div v-if="parseModules(data.item.subs_plan_modules).length > 0">
                      <span
                        v-for="(module, index) in parseModules(data.item.subs_plan_modules)"
                        :key="index">
                        <b-badge
                          style="cursor:pointer;"
                          class="border mr-2 mb-2 font-weight-normal"
                          variant="none">
                          <span>{{ module }}</span>
                        </b-badge>
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="subscriptionPlanEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showSubscriptionPlanDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSubscriptionPlanAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <SubscriptionPlanAdd :propOpenedInModal="true" @emitCloseSubscriptionPlanAddModal="closeSubscriptionPlanAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="subscriptionPlanAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubscriptionPlanAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSubscriptionPlanEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <SubscriptionPlanEdit :propOpenedInModal="true" :propSubscriptionPlanObj="subscriptionPlanEditObj" @emitCloseSubscriptionPlanEditModal="closeSubscriptionPlanEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="subscriptionPlanEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubscriptionPlanEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSubscriptionPlanDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSubscriptionPlanDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="subscriptionPlanDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style scoped lang="scss">
  @media (max-width: 379px){
    iq-card{
      padding-top: 20px;
    }
  @media (max-width: 420px) {
    .card-title{
      width: 110px;
    }
  }
}
</style>
<script>
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import SubscriptionPlanAdd from "./SubscriptionPlanAdd.vue"
import SubscriptionPlanEdit from "./SubscriptionPlanEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SubscriptionPlanList",
  components: {
    SubscriptionPlanAdd,
    SubscriptionPlanEdit
  },
  data () {
    return {
      apiName: "subscriptionPlan_list",
      cvCardTitle: "Subscription Plans",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Subscription Plan",
      cvAddModalHeader: "Add Subscription Plan",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Subscription List Response",
      showModelSubscriptionPlanAdd: false,
      showModelSubscriptionPlanEdit: false,
      showModelSubscriptionPlanDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Sub Id", key: "subs_plan_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Name", key: "subs_plan_name", class: "text-left align-text-top", sortable: true },
        { label: "Type", key: "subs_plan_type", class: "text-left align-text-top", sortable: true },
        { label: "Interval", key: "subs_plan_interval", class: "text-left align-text-top", sortable: true },
        { label: "Interval Count", key: "subs_plan_interval_count", class: "text-left align-text-top", sortable: true },
        { label: "Modules", key: "subs_plan_modules", class: "text-left align-text-top", sortable: true },
        { label: "Currency", key: "currency_name", class: "text-left align-text-top", sortable: true },
        { label: "Event Name", key: "event_name", class: "text-left align-text-top", sortable: true },
        // { label: "Plan Description", key: "subs_plan_desc", class: "text-left align-text-top", sortable: true },
        { label: "Price", key: "subs_plan_price", class: "text-left align-text-top", sortable: true },
        { label: "Price List for GidePlus", key: "subs_plan_price_gideplus", class: "text-left align-text-top", sortable: true },
        { label: "Created On", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      subscriptionPlanObjList: null,
      subscriptionPlanEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.subscriptionPlanList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("Do MMM YYYY")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanList
     */
    async subscriptionPlanList () {
      try {
        let subscriptionPlanListResp = await SubscriptionPlans.subscriptionPlanList(this)

        if (subscriptionPlanListResp.resp_status) {
          this.subscriptionPlanObjList = subscriptionPlanListResp.resp_data.data
          this.totalRows = subscriptionPlanListResp.resp_data.count
        }
        else {
          this.toastMsg = subscriptionPlanListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionPlanListXXXXXXXX() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanAdd
     */
    subscriptionPlanAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subscriptionPlan_add")
        }
        else {
          this.showModelSubscriptionPlanAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanAdd() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanEdit
     */
    subscriptionPlanEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subscriptionPlan_edit/" + this.subscriptionPlanEditObj.subs_plan_id)
        }
        else {
          this.subscriptionPlanEditObj = item
          this.showModelSubscriptionPlanEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanEdit() and Exception:", err.message)
      }
    },
    /**
     * showSubscriptionPlanDeleteDialog
     */
    showSubscriptionPlanDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSubscriptionPlanDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSubscriptionPlanDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanDelete
     */
    async subscriptionPlanDelete () {
      try {
        let subscriptionPlanDelResp = await SubscriptionPlans.subscriptionPlanDelete(this, this.delObj.subs_plan_id)
        await ApiResponse.responseMessageDisplay(this, subscriptionPlanDelResp)
        if (subscriptionPlanDelResp && !subscriptionPlanDelResp) {
          this.showModelSubscriptionPlanDelete = false
          return false
        }
        let index = this.subscriptionPlanObjList.indexOf(this.delObj)
        this.subscriptionPlanObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSubscriptionPlanDelete = false
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSubscriptionPlanAddModal
     */
    closeSubscriptionPlanAddModal (subscriptionPlanAddData) {
      try {
        if (subscriptionPlanAddData) {
          if (this.subscriptionPlanObjList && this.subscriptionPlanObjList.length >= 1) {
            let subsPlanObjLength = this.subscriptionPlanObjList.length
            let lastId = this.subscriptionPlanObjList[subsPlanObjLength - 1]["id"]
            subscriptionPlanAddData.id = lastId + 1
          }
          else {
            this.subscriptionPlanObjList = []
            subscriptionPlanAddData.id = 11111
          }
          // subscriptionPlanAddData.subs_plan_id = `SUBSPLAN${subscriptionPlanAddData.id}`
          subscriptionPlanAddData.created_on = moment()
          this.subscriptionPlanObjList.unshift(subscriptionPlanAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelSubscriptionPlanAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closesubscriptionPlanAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSubscriptionPlanEditModal
     */
    closeSubscriptionPlanEditModal () {
      try {
        this.showModelSubscriptionPlanEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSubscriptionPlanEditModal() and Exception:", err.message)
      }
    },
    /**
     * parseModules
     */
    parseModules (modules) {
      let moduleObjList = []
      try {
        moduleObjList = modules.split(",")
      }
      catch (err) {
        console.error("Exception occurred at parseModules() and Exception:", err.message)
      }
      return moduleObjList
    }
  }
}
</script>
